import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../framework/core/http/http';
import { InvoiceAccrualDefinition } from '../../unientities';

@Injectable()
export class InvoiceAccrualDefinitionService extends BizHttp<InvoiceAccrualDefinition> {
    constructor(protected http: UniHttp) {
        super(http);
        this.relativeURL = 'invoiceaccrualdefinitions.RelativeUrl';
    }
}
